// third-party
import { createSlice, createSelector } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
    page: 0,
    rowsPerPage: 10,

    isLoading: false,
    isLoading2: false,
    message: '',

    testRequest: [],
    testRequestData: {},
    testRequestDetailsByTestRequestId: []
};

const slice = createSlice({
    name: 'testRequest',
    initialState,
    reducers: {
        // Pagination
        setPage(state, action) {
            state.page = action.payload;
        },
        setRowsPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        // Pagination
        startAddingNewTest(state, action) {
            state.isLoading = true;
        },
        startTestRequestStatusUpdate(state, action) {
            state.isLoading = true;
        },
        startAllTestRequest(state, action) {
            state.isLoading = true;
            state.isLoading2 = true;
        },
        setTestRequest(state, action) {
            state.testRequest = action.payload;
        },
        startTestRequestData(state, action) {
            state.isLoading = true;
        },
        setTestRequestData(state, action) {
            state.testRequestData = action.payload;
        },
        startDeleteTestByID(state, action) {
            state.isLoading = true;
        },
        startTestRequestDataByFilter(state, action) {
            state.isLoading = true;
            state.isLoading2 = true;
        },
        // // Details Table Data
        startUpdateTestRequestData(state, action) {
            state.isLoading = true;
        },
        startTestRequestDetailsByTestRequestId(state, action) {
            state.isLoading = true;
        },
        setTestRequestDetailsByTestRequestId(state, action) {
            state.testRequestDetailsByTestRequestId = action.payload;
        },
        startAddMoreTestReqDetail(state, action) {
            state.isLoading = true;
        },
        startUpdateIterationOnTestReq(state, action) {
            state.isLoading = true;
        },
        startUpdateCustomer(state, action) {
            state.isLoading = true;
        },
        // ////////////////////
        completedAction(state, action) {
            state.isLoading = false;
            state.isLoading2 = false;
        }
    }
});
// Actions Exports
export const {
    setPage,
    setRowsPerPage,
    startAddingNewTest,
    startTestRequestStatusUpdate,
    startAllTestRequest,
    setTestRequest,
    startDeleteTestByID,
    startTestRequestData,
    setTestRequestData,
    startTestRequestDataByFilter,
    startUpdateTestRequestData,
    startTestRequestDetailsByTestRequestId,
    setTestRequestDetailsByTestRequestId,
    startAddMoreTestReqDetail,
    startUpdateIterationOnTestReq,
    startUpdateCustomer,
    completedAction
} = slice.actions;
// Reducer
export default slice.reducer;

// selectors

const testRequestsStates = (state) => state.testRequests;
/// Data Loading Selector
export const isLoadingSelector = createSelector([testRequestsStates], (testRequesDataState) => testRequesDataState.isLoading);
export const isLoading2Selector = createSelector([testRequestsStates], (testRequesDataState) => testRequesDataState.isLoading2);
// Test-Requests Selector
export const testRequestsSelector = createSelector([testRequestsStates], (testRequesDataState) => testRequesDataState.testRequest);
// Test-Requests-data
export const testRequestDataSelector = createSelector([testRequestsStates], (testRequesDataState) => testRequesDataState.testRequestData);
// Test-Request-Details-By-Test_request-id
export const testRequestDetailssSelector = createSelector(
    [testRequestsStates],
    (testRequesDataState) => testRequesDataState.testRequestDetailsByTestRequestId
);
// Page Selector
export const pageSelector = createSelector([testRequestsStates], (testRequesDataState) => testRequesDataState.page);
// Row Per Page Selector
export const rowsPerPageSelector = createSelector([testRequestsStates], (testRequesDataState) => testRequesDataState.rowsPerPage);
