import { securedAxiosAPI } from 'utils/axios';
import { store } from '../../store';
// APIS

// Secured Routes

const securedRoute = {
    add: 'testRequest/add',
    base: 'testRequest',
    byPagination: 'testRequest/byPagination',
    updateCustomer: 'testRequest/update-customer',
    // customer Portal
    customerTestReqList: 'customerPortal/customerPortalGetByPagination',
    requestDetail: 'customerPortal/customerGetTestRequestById'
};
export const getAllTestRequest = async () => {
    const res = await securedAxiosAPI.get(`${securedRoute?.base}`);
    return res.data;
};

export const getTestRequestsByPagination = async (data) => {
    const { getState } = store;
    const { page, rowsPerPage } = getState().testRequests;
    const { loggedInPortal } = getState().user;
    const { customerId } = getState().user.loggedInUser;
    let res;
    if (loggedInPortal === 'LAB') {
        res = await securedAxiosAPI.post(securedRoute?.byPagination, { page, rowsPerPage, ...data });
    } else if (loggedInPortal === 'CUSTOMER') {
        res = await securedAxiosAPI.post(securedRoute?.customerTestReqList, { page, rowsPerPage, customer_id: customerId });
    }
    return res.data;
};

export const getTestRequestData = async (id) => {
    const { getState } = store;

    const { loggedInPortal } = getState().user;
    const { customerId } = getState().user.loggedInUser;
    let res;
    if (loggedInPortal === 'LAB') {
        res = await securedAxiosAPI.get(`${securedRoute?.base}/${id}`);
    } else if (loggedInPortal === 'CUSTOMER') {
        // customer portal
        res = await securedAxiosAPI.post(securedRoute?.requestDetail, { test_request_id: id, customer_id: customerId });
    }

    return res.data[0];
};
export const addNewTest = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.add, data);
    return res.data;
};
export const deleteById = async (data) => {
    const res = await securedAxiosAPI.delete(`${securedRoute?.base}/${data}`);
    return res.data;
};

export const testRequestFilter = async (data) => {
    const { getState } = store;
    const { page, rowsPerPage } = getState().testRequests;
    // const { loggedInPortal } = getState().user;
    const { customerId } = getState().user.loggedInUser;
    // let res;
    // if (loggedInPortal === 'LAB') {
    //     res = await securedAxiosAPI.post(securedRoute?.byPagination, { page, rowsPerPage });
    // } else if (loggedInPortal === 'CUSTOMER') {
    //     res = await securedAxiosAPI.post(securedRoute?.customerTestReqList, { page, rowsPerPage, customer_id: customerId });
    //     //
    // }

    const res = await securedAxiosAPI.post(`${securedRoute?.base}/dynamic-filter`, { ...data, page, rowsPerPage, customer_id: customerId });
    return res.data;
};
// Update status
export const testReqStatusUpdate = async (data) => {
    const res = await securedAxiosAPI.post(`${securedRoute?.base}/update-status`, data);
    return res.data[0];
};
// update-customer
export const updateCustomer = async (data) => {
    const res = await securedAxiosAPI.post(`${securedRoute?.updateCustomer}`, data);
    return res;
};
