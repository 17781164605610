import * as React from 'react';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import { IconButton } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useLocation, useNavigate } from 'react-router-dom';
import { startDeleteCustomer } from 'store/slices/customer/customer';
import { useDispatch } from 'react-redux';
import { Edit } from '@mui/icons-material';

// /customer-management/:id/user-management
export default function TestRequestMenu({ row }) {
    const pathName = useLocation().pathname;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton color="primary" onClick={handleClick} aria-label="moreMenu" size="small">
                <MoreVertIcon fontSize="inherit" />
            </IconButton>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <Paper>
                    <MenuList>
                        <MenuItem onClick={() => navigate(`/sample-reception/test-requests/${row?.id}/request_details`)}>
                            <ListItemIcon>
                                <VisibilityIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText textAlign="end">View Detail</ListItemText>
                        </MenuItem>
                        <MenuItem disabled={row.status > 0.9} onClick={() => navigate(`/sample-reception/test-requests/${row?.id}/edit`)}>
                            <ListItemIcon>
                                <Edit fontSize="small" />
                            </ListItemIcon>
                            <ListItemText textAlign="end">Edit</ListItemText>
                        </MenuItem>
                    </MenuList>
                </Paper>
            </Menu>
        </div>
    );
}
